<template>
  <v-container>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          class="pa-4"
        >
          <h3 class="primary--text py-2">{{ isTravel ? 'Destination' : 'Property' }} Details</h3>
          <p>
            Please provide information required to issue Coverage.
          </p>

        </v-col>
      </v-row>
      <v-row v-if="quoteErrors" no-gutters>
        <v-col
          cols="12"
          sm="12"
        >
          <v-alert
            dense
            outlined
            type="error"
            class="text-left"
          >
            {{ quoteErrors }}
          </v-alert>

        </v-col>
      </v-row>
      <!-- <v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          class="pa-4"
        >
          <v-select
            ref="protection"
            v-model="form.protection"
            :items="['Sample 1', 'Other']"
            label="Select Protection"
            outlined
            :rules="emptyRules.protection"
          ></v-select>
        </v-col>
      </v-row> -->
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="8"
          class="pa-4"
          style="border-right: 1px solid gray"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="12"
              class="px-2" 
            >
              <v-text-field
                ref="destination_name"
                v-model="form.destination_name"
                label="Destination Name"
                outlined
                :rules="emptyRules.destination_name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="6"
              class="px-2"
            >
              <v-autocomplete
                ref="country_to"
                v-model="form.country_to"
                :items="countries"
                label="Country"
                item-text="Country"
                item-value="CountryId"
                outlined
                :rules="emptyRules.country_to"
              ></v-autocomplete> 
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="px-2"
            >
              <v-autocomplete
                ref="state_to"
                v-model="form.state_to"
                item-text="state"
                item-value="stateid"
                :items="statesTo"
                :disabled="statesTo.length < 1"
                label="Province/Region"
                outlined
                :rules="emptyRules.state_to"
              ></v-autocomplete> 
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="8"
              class="px-2"
            >
              <v-text-field
                ref="city"
                v-model="form.city"
                label="City"
                outlined
                :rules="emptyRules.city"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="px-2"
            >
              <v-text-field
                ref="zip"
                v-model="form.zip"
                label="Postal Code"
                outlined
                :rules="emptyRules.zip"
              ></v-text-field> 
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="8"
              class="px-2"
            >
              <v-text-field
                ref="address"
                v-model="form.address"
                label="Address"
                outlined
                :rules="emptyRules.address"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="px-2"
            >
              <v-text-field
                ref="apt"
                v-model="form.apt"
                label="Apt, suite, unit, etc."
                outlined
                :rules="emptyRules.apt"
              ></v-text-field> 
            </v-col>
          </v-row>

        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="pa-4"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="12"
            >
              <vuetify-money
                ref="coverage_amount"
                v-model="form.coverage_amount"
                :options="currencyOptions"
                outlined
                :label="isTravel ? 'Trip Total' : 'Rental Total'"
                type="text"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                ref="booking_number"
                v-model="form.booking_number"
                :label="isTravel ? 'Reservation Number(s)' : 'Booking'"
                outlined
                :rules="emptyRules.booking_number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="12"
            >
              <text-calendar
                ref="booking_date"
                v-model="form.booking_date"
                label="Booking Date"
                outlined
                :rules="emptyRules.booking_date"
              ></text-calendar>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="6"
              class="px-1"
            >
              <text-calendar
                ref="start_date"
                v-model="form.start_date"
                label="Start Date"
                outlined
                :rules="emptyRules.start_date"
              ></text-calendar>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="px-1"
            >
              <text-calendar
                ref="end_date"
                v-model="form.end_date"
                label="End Date"
                outlined
                :rules="emptyRules.end_date"
              ></text-calendar>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import isBefore from 'date-fns/isBefore'
import { CURRENCY } from '@/open-order/constants/currency.js'
import FormRefMixin from '@/open-order/mixins/FormRefMixin'
import LocationsMixin from '@/open-order/mixins/LocationsMixin'
import TextCalendar from '@/open-order/components/TextCalendar'
import { FORM_FIELD_NAMES } from '../../constants/formConstants'

const dateFns = { isBefore }

export default {
  name: 'CoverageDetails',
  components: {
    TextCalendar
  },
  mixins: [FormRefMixin, LocationsMixin],
  data: () => ({
    valid: true,
    rules: {
      // protection: [(v) => !!v || 'Protection required'],
      destination_name: [(v) => !!v || 'Destination Name required'],
      country_to: [(v) => !!v || 'Country required'],
      state_to: [],
      city: [(v) => !!v || 'City required'],
      zip: [(v) => !!v || 'Postal Code required'],
      address: [(v) => !!v || 'Address required'],
      apt: [],
      coverage_amount: [(v) => !!v || 'Total required'],
      booking_number: [(v) => !!v || 'Booking Number required'],
      booking_date: [],
      start_date: [],
      end_date: []
    }
  }),

  computed: {
    ...mapState({
      quoteErrors: (state) => state.orderForm.errors.quote
    }),
    ...mapGetters({
      isTravel: 'orderForm/isTravel'
    }),
    startDateObj() {
      return new Date(this.form.start_date)
    },
    endDateObj() {
      return new Date(this.form.end_date)
    },
    bookingDateObj() {
      return new Date(this.form.booking_date)
    },
    bookingDateRules() {
      return [
        (v) => !!v || 'Booking Date required',
        () => dateFns.isBefore(this.bookingDateObj, this.startDateObj) || 'Booking Date cannot be later than the Start Date'
      ]
    },
    startDateRules() {
      return [
        (v) => !!v || 'Start Date required',
        () => dateFns.isBefore(this.bookingDateObj, this.startDateObj) || 'Booking Date cannot be later than the Start Date',
        () => dateFns.isBefore(this.startDateObj, this.endDateObj) || 'Start Date cannot be later than the End Date'
      ]
    },
    endDateRules() {
      return [
        (v) => !!v || 'End Date required',
        () => dateFns.isBefore(this.startDateObj, this.endDateObj) || 'Start Date cannot be later than the End Date'
      ]
    },
    currencyOptions() {
      return CURRENCY[0]
    }
  },

  created(){
    this.initializeForm()
  },

  mounted() {
    this.initializeForm()
    this.rules.booking_date = this.bookingDateRules
    this.rules.start_date = this.startDateRules
    this.rules.end_date = this.endDateRules
    this.rules.state_to = this.stateToRule
  },
  methods: {
    ...mapActions({
      getStates: 'fieldData/getStates',
      updateOrder: 'orderForm/updateOrder'
    }),

    async initializeForm() {
      try {
        const { query } = this.$route;

        for (const queryKey in query) {
          if (Object.prototype.hasOwnProperty.call(query, queryKey)) {
            const value = query[queryKey];

            const mappedKey = FORM_FIELD_NAMES[queryKey];

            if (mappedKey) {
              switch (queryKey) {
                case 'coverage_amount':
                  const amount = parseFloat(value);
                  if (!isNaN(amount) && amount > 0) {
                    this.form[mappedKey] = amount;
                  }
                  break;
                default:
                  if (value != null) {
                    this.form[mappedKey] = value;
                  }
                  break;
              }
            }
          }
        }

      } catch (error) {
        console.log(error);
      }
    },

    async validateData() {
      Object.keys(this.emptyRules).forEach((key) => {
        this.emptyRules[key] = this.rules[key]
      })

      await this.$refs.form.validate()
      if (this.valid) {
        this.updateOrder(this.form)
      }

      return this.valid
    }
  }
}
</script>

<style>

</style>