<template>
  <v-container ref="container" v-resize="iframeHeightNotify">
    <v-row
      align="center"
      justify="center"
      no-gutters
    >
      <v-col
        cols="12"
      >
        <!-- 
          multiple conitionals w/ "steps - 1" b/c we want to hide
          QuoteAndSummary until the user gets to that page
        -->
        <v-stepper v-model="e1" alt-labels>
          <v-stepper-header v-if="displaySteps.length > 0 & e1 <= displaySteps.length">
            <template v-for="n in displaySteps.length">
              <v-stepper-step
                :key="`${n}-step`"
                :complete="e1 > n"
                :step="n"
                color="primary"
              >
                {{ displaySteps[n-1].name }}
              </v-stepper-step>
              <v-divider
                v-if="n < displaySteps.length"
                :key="n"
              ></v-divider>
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content
              v-for="n in steps"
              :key="`${n}-content`"
              :step="n"
            >
  
              <component 
                :is="componentSteps[n-1].component" 
                v-if="isMounted" 
                :ref="`step${n}`" 
                :step="e1"
                @next-step="forceNextStep"
              />
            
              <v-row align="center" justify="center">
                <v-col cols="6" align="left" justify="left">
                  <!-- hide on first step and completed page -->
                  <v-btn
                    v-if="e1 != 1 && e1 !== steps"
                    color="primary"
                    @click="prevStep(n)"
                  >
                    Previous
                  </v-btn>
                </v-col>
                <v-col v-if="isMounted" cols="6" align="right" justify="right">
                  <v-btn
                    v-if="e1 < displaySteps.length"
                    color="primary"
                    @click="nextStep(n)"
                  >
                    Next
                  </v-btn>
                  <v-btn
                    v-if="e1 === displaySteps.length"
                    dark
                    color="primary"
                    :loading="loading.quote"
                    @click.prevent="nextStep(n)"
                  >
                    Get Quote
                  </v-btn>
                </v-col>
              </v-row>
                
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
          
      </v-col>
    </v-row>

    <v-overlay
      :absolute="true"
      :value="loadingState"
    >
      <v-progress-circular
        :size="250"
        :width="9"
        color="primary"
        indeterminate
      ></v-progress-circular>

    </v-overlay>

  </v-container>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import { DEFAULT } from '@/open-order/constants/steps'
import { mapActions, mapMutations, mapState } from 'vuex'
import CoverageAndLocation from '@/open-order/components/steps/CoverageAndLocation'
import PrimaryCoverageHolder from '@/open-order/components/steps/PrimaryCoverageHolder'
import CoverageDetails from '@/open-order/components/steps/CoverageDetails'
import QuoteAndSummary from '@/open-order/components/steps/QuoteAndSummary'
import Completed from '@/open-order/components/steps/Completed'

const _ = { isEmpty }

export default {
  name: 'OrderForm',

  components: {
    CoverageAndLocation,
    PrimaryCoverageHolder,
    CoverageDetails,
    QuoteAndSummary,
    Completed
  },

  data: () => ({
    componentSteps: DEFAULT,
    loadingState: false,
    isMounted: true,
    e1: 1
  }),

  computed: {
    ...mapState({
      loading: (state) => state.orderForm.loading,
      quote: (state) => state.orderForm.quote,
      meta: (state) => state.microsite.meta
    }),
    steps() {
      return this.componentSteps.length
    },
    displaySteps() {
      return this.componentSteps.filter((step) => step.showStep !== false)
    }
  },
  mounted() {
    const [primary, secondary] = [this.$route.query.primary, this.$route.query.secondary]

    this.$vuetify.theme.themes.light.primary = primary ? `#${primary}` : this.$vuetify.theme.themes.light.primary
    this.$vuetify.theme.themes.light.secondary = secondary ? `#${secondary}` : this.$vuetify.theme.themes.light.secondary

    if (!this.meta) {
      this.getMeta()
    }

  },
  methods: {
    ...mapActions({
      getQuote: 'orderForm/getQuote',
      getMeta: 'microsite/getMeta'
    }),
    forceNextStep() {
      if (this.e1 < this.steps) {
        this.e1++
      }
    },
    async nextStep (n) {
      this.applyLocations(n)

      // calls validateData from child component
      const isStepValid = await this.$refs[`step${n}`][0].validateData()

      // if validateData does not pass, do not allow to the next step
      if (!isStepValid) return

      // Check if we want to show the No Doc warning dialog before we want to continue
      if (n === this.steps) {
        this.e1 = n
      }
      else if (n === this.displaySteps.length) {
        // For last displayStep page get the quote from the backend
        await this.getQuote()
        // If the quote object was not set that means we have an error and must prevent the next step from loading
        if (!_.isEmpty(this.quote)) {
          this.e1 = n + 1
        }
      } else {
        this.e1 = n + 1
      }
    },
    prevStep (n) {
      this.applyLocations(n)
      if (n === 0) {
        this.e1 = 1
      } else {
        this.e1 = n - 1
      }      
    },
    iframeHeightNotify() {
      const msg = {
        height: this.$refs.container.scrollHeight
      }

      window.parent.postMessage(msg,'*')
    },
    applyLocations(n) {
      // every time we change steps, match location values
      const currentStepForm = this.$refs[`step${n}`][0].form

      // don't call on quote/summary step 
      if (n >= this.displaySteps.length) {
        return
      }
      for (let i = 1; i <= this.displaySteps.length; i++) {
        const otherStepForm = this.$refs[`step${i}`][0].form
        const locations = ['state_from', 'state_to', 'country_from', 'country_to']

        if (i === n) continue

        locations.forEach((loc) => {
          if (loc in otherStepForm && loc in currentStepForm) {
            otherStepForm[loc] = currentStepForm[loc]
          }
        })
      }
    }
  }
}
</script>
<style scoped>
.container {
  padding: 0px;
  width: 100%;
  max-width: unset;
}
</style>
