export default {
  data: () => ({
    valid: true,
    form: {},
    emptyRules: {}
  }),
  methods: {
    setForm() {
      return Object.keys(this.rules).reduce((acc, rule) => ({ ...acc, [rule]: '' }), {})
    },
    setEmptyRules() {
      return Object.keys(this.rules).reduce((acc, rule) => ({ ...acc, [rule]: [] }), {})
    }
  },
  mounted() {
    this.form = this.setForm()
    this.emptyRules = this.setEmptyRules()
  }
}