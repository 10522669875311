import { mapActions, mapState } from 'vuex'
import { removeWhitespaceAndLowercase, findState } from '../utils/util'

export default {
  computed: {
    ...mapState({
      coverageLocations: (state) => state.fieldData.coverageLocations,
      countries: (state) => state.fieldData.countries,
      statesTo: (state) => state.fieldData.statesTo,
      statesFrom: (state) => state.fieldData.statesFrom
    }),
    stateFromRule() {
      return this.statesFrom.length > 0 ? [(v) => !!v || 'State required'] : []
    },
    stateToRule() {
      return this.statesTo.length > 0 ? [(v) => !!v || 'State required'] : []
    }
  },
  watch: {
    'form.country_from': {
      async handler(countryId) {
        if (countryId) {
          await this.getStates({ countryId, stateSet: 'StatesFrom' })
        }
        // If there is no states for the country remove required rule
        this.rules.state_from = this.statesFrom.length > 0 ? [(v) => !!v || 'State required'] : []
        const formStateFrom = removeWhitespaceAndLowercase(this.form.state_from);

        const stateFrom = findState(this.statesFrom, formStateFrom);

        this.form.state_from = stateFrom ? stateFrom.stateid : null
      }
    },
    'form.country_to': {
      async handler(countryId) {

        if (countryId) {
          await this.getStates({ countryId, stateSet: 'StatesTo' })
        }
        // If there is no states for the country remove required rule
        this.rules.state_to = this.statesTo.length > 0 ? [(v) => !!v || 'State required'] : []
        const formStateTo = removeWhitespaceAndLowercase(this.form.state_to);

        const stateTo = findState(this.statesTo, formStateTo);
        
        this.form.state_to = stateTo ? stateTo.stateid : null
      }
    }
  }
}