/**
 * Removes whitespace characters and converts a string to lowercase.
 * @param {string} 
 * @returns {string} 
 */
export const removeWhitespaceAndLowercase = (inputString = "") => {
  if (typeof inputString !== "string") return inputString;

  return inputString.replace(/\s/g, "").toLowerCase();
};

/**
 * Finds a state within the given array of states based on the specified formStateValue.
 * @param {Array} statesArray 
 * @param {string} formStateValue 
 * @returns {Number|null} Returns stateId
 */
export const findState = (statesArray, formStateValue) => {
  return (
    statesArray.find((state) => {
      const { stateid, state: stateName } = state;
      const currentStateName = stateName
        ? removeWhitespaceAndLowercase(stateName)
        : null;
      return (
        formStateValue === stateid ||
        (currentStateName && formStateValue === currentStateName)
      );
    }) || null
  );
};
