export const FORM_FIELD_NAMES  = {
    destination_name: 'destination_name',
    destination_city: 'city',
    destination_zip: 'zip',
    destination_address: 'address',
    destination_apt: 'apt',
    booking_number: 'booking_number',
    booking_date: 'booking_date',
    start_date: 'start_date',
    end_date: 'end_date',
    coverage_amount: 'coverage_amount'
  };