export const DEFAULT = [
  {
    name: 'Coverage and Location',
    component: 'CoverageAndLocation'
  },
  {
    name: 'Primary Coverage Holder',
    component: 'PrimaryCoverageHolder'
  },
  {
    name: 'Coverage Details',
    component: 'CoverageDetails'
  },
  {
    name: 'Quote',
    component: 'QuoteAndSummary',
    showStep: false
  },
  {
    name: 'Completed',
    component: 'Completed',
    showStep: false
  }
]