export const CURRENCY = [
  {
    currency: 'usd',
    locale: 'en-US',
    prefix: '$',
    suffix: null,
    length: 9,
    precision: 2
  },
  {
    currency: 'euro',
    locale: 'en-GB',
    prefix: '€',
    suffix: null,
    length: 9,
    precision: 2
  },
  {
    curreny: 'default',
    locale: 'en-GB',
    prefix: '€',
    suffix: null,
    length: 9,
    precision: 2
  }
]