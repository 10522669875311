<template>
  <v-container>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          class="pa-4"
        >
          <h3 class="primary--text py-2">Primary Coverage Holder</h3>
          <p>
            Please provide the following Primary Coverage Holder information. All Fields are required.
          </p>

        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="8"
          class="pa-4"
          style="border-right: 1px solid gray"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="6"
              class="px-2"
            >
              <v-text-field
                ref="first_name"
                v-model="form.first_name"
                label="First Name"
                outlined
                :rules="emptyRules.first_name"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="px-2"
            >
              <v-text-field
                ref="last_name"
                v-model="form.last_name"
                label="Last Name"
                outlined
                :rules="emptyRules.last_name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="6"
              class="px-2"
            >
              <v-autocomplete
                ref="country_from"
                v-model="form.country_from"
                :items="countries"
                label="Country"
                province="country"
                :disabled="countries.length < 1"
                item-text="Country"
                item-value="CountryId"
                outlined
                :rules="emptyRules.country_from"
              ></v-autocomplete> 
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="px-2"
            >
              <v-autocomplete
                ref="state_from"
                v-model="form.state_from"
                item-text="state"
                item-value="stateid"
                :items="statesFrom"
                :disabled="statesFrom.length < 1"
                label="Province/Region"
                outlined
                :rules="emptyRules.state_from"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="8"
              class="px-2"
            >
              <v-text-field
                ref="coverage_city"
                v-model="form.coverage_city"
                label="City"
                outlined
                :rules="emptyRules.coverage_city"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="px-2"
            >
              <v-text-field
                ref="coverage_zip"
                v-model="form.coverage_zip"
                label="Postal Code"
                outlined
                :rules="emptyRules.coverage_zip"
              ></v-text-field> 
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="8"
              class="px-2"
            >
              <v-text-field
                ref="coverage_address"
                v-model="form.coverage_address"
                label="Address"
                outlined
                :rules="emptyRules.coverage_address"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="px-2"
            >
              <v-text-field
                ref="coverage_apt"
                v-model="form.coverage_apt"
                label="Apt, suite, unit, etc."
                outlined
                :rules="emptyRules.coverage_apt"
              ></v-text-field> 
            </v-col>
          </v-row>

        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="pa-4"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                ref="phone_number"
                v-model="form.phone_number"
                label="Phone Number"
                outlined
                :rules="emptyRules.phone_number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                ref="email_address"
                v-model="form.email_address"
                label="Email Address"
                outlined
                :rules="emptyRules.email_address"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                ref="validate_email_address"
                v-model="form.validate_email_address"
                label="Validate Email Address"
                outlined
                :rules="emptyRules.validate_email_address"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FormRefMixin from '@/open-order/mixins/FormRefMixin'
import LocationsMixin from '@/open-order/mixins/LocationsMixin'

export default {
  name: 'PrimaryCoverageHolder',
  mixins: [FormRefMixin, LocationsMixin],
  data: () => ({
    valid: true,
    rules: {
      first_name: [(v) => !!v || 'First Name required'],
      last_name: [(v) => !!v || 'Last Name required'],
      country_from: [(v) => !!v || 'Country required'],
      state_from: [],
      coverage_city: [(v) => !!v || 'City required'],
      coverage_zip: [(v) => !!v || 'Postal Code required'],
      coverage_address: [(v) => !!v || 'Address required'],
      coverage_apt: [],
      phone_number: [(v) => !!v || 'Phone Number required'],
      email_address: [],
      validate_email_address: []
    }
  }),
  computed: {
    emailConfirmationRules() {
      return [
        (v) => !!v || 'E-mail is required',
        () => (this.form.email_address === this.form.validate_email_address) || 'E-mails must match',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ]
    }
  },
  mounted() {
    this.rules.email_address = this.emailConfirmationRules
    this.rules.validate_email_address = this.emailConfirmationRules
    this.rules.state_from = this.stateFromRule
  },
  methods: {
    ...mapActions({
      getStates: 'fieldData/getStates',
      updateOrder: 'orderForm/updateOrder'
    }),
    async validateData() {
      Object.keys(this.emptyRules).forEach((key) => {
        this.emptyRules[key] = this.rules[key]
      })

      await this.$refs.form.validate()
      if (this.valid) {
        this.updateOrder(this.form)
      }

      return this.valid
    }
  }
}
</script>

<style>

</style>