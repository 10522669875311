<template>
  <v-card
    rounded
    elevation="10"
    max-width="750"
    style="margin:0 auto"
    :loading="loading.payment"
  >
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row v-if="isManager" no-gutters>
          <v-col class="px-2">
            <v-radio-group v-model="pay_method">
              <v-radio
                label="Default"
                value="Default"
              />
              <v-radio
                label="Direct"
                value="Direct"
              />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-row no-gutters>
            <v-col class="px-2">
              <v-text-field
                ref="card_number"
                v-model="form.card_number"
                v-mask="'#### #### #### ###?#'"
                prepend-inner-icon="mdi-credit-card"
                label="Card Number"
                mask="credit-card"
                outlined
                :rules="!disableFields ? emptyRules.card_number : []"
                :disabled="disableFields"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="px-2">
              <v-text-field
                ref="name_on_card"
                v-model="form.name_on_card"
                label="Name on Card"
                outlined
                :rules="!disableFields ? emptyRules.name_on_card : []"
                :disabled="disableFields"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col sm="5" class="px-2">
              <v-select
                ref="month"
                v-model="form.month"
                class="p-10"
                label="Month"
                outlined
                :items="ccMonths"
                :rules="!disableFields ? emptyRules.month : []"
                :disabled="disableFields"
              ></v-select>
            </v-col>
            <v-col sm="5" class="px-2"> 
              <v-select
                ref="year"
                v-model="form.year"
                label="Year"
                outlined
                :items="ccYears"
                :rules="!disableFields ? emptyRules.year : []"
                :disabled="disableFields"
              ></v-select>
            </v-col>
            <v-col sm="2" class="px-2">
              <v-text-field
                ref="ccv"
                v-model="form.ccv"
                v-mask="'###?#'"
                label="CCV"
                outlined
                :rules="!disableFields ? emptyRules.ccv : []"
                :disabled="disableFields"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-card elevation="0">
                <v-card-title>Disclosures and Agreements</v-card-title>
                <v-card-text class="text-left">
                  <ul>
                    <li>This purchase is subject to the following: <a target="_blank" href="https://playtravelprotection.com/purchase-disclosures/">Purchase Disclosures</a></li>
                    <li>I agree that the information I have provided is true to the best of my knowledge and belief.</li>
                    <li>I understand that any misrepresentations may void my plan/policy/coverage and if any statements are knowingly made with the intent to defraud I may be subject to legal action.</li>
                    <li v-if="programsExist"><a target="_blank" href="/programs">View Sample Certificate</a></li>
                  </ul>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-checkbox
                ref="authorize"
                v-model="form.authorize"
                :rules="!disableFields ? emptyRules.authorize : []"
                :disabled="disableFields"
                label="I agree that RentalGuardian and its partners can collect, use and process sensitive personal data for individuals covered under this policy as needed in order to administer the policy and ensure that coverage is properly provided. I understand that this consent may be withdrawn at any time; however, if this happens RentalGuardian and its partners may not be able to continue providing the services."
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-alert
                v-if="errors.payment"
                dense
                outlined
                type="error"
                class="text-left"
              >
                {{ errors.payment }}
              </v-alert>
              <v-btn
                color="primary"
                :disabled="loading.payment"
                @click="process"
              >
                Process Payment
              </v-btn>
            </v-col>
          </v-row>
        </v-row></v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import FormRefMixin from '@/open-order/mixins/FormRefMixin'
import { mapActions, mapState, mapGetters } from 'vuex'
import isEmpty from 'lodash/isEmpty'

const _ = { isEmpty }

export default {
  mixins: [FormRefMixin],
  data: () => ({
    valid: true,
    pay_method: null,
    rules: {
      card_number: [(v) => !!v || 'Card Number Required', ((v) => (v || '').length >= 18 ) || 'Invalid Credit Card Number'],
      name_on_card: [(v) => !!v || 'Name on Card Required'],
      month: [],
      year: [],
      ccv: [(v) => !!v || 'Code Required'],
      authorize: [(v) => !!v || 'Must Check']
    }
  }),
  computed: {
    ...mapState({
      errors: (state) => state.orderForm.errors,
      policy: (state) => state.orderForm.policy,
      loading: (state) => state.orderForm.loading
    }),
    ...mapGetters({
      isManager: 'orderForm/isManager'
    }),
    disableFields() {
      return this.isManager && this.pay_method === 'Default'
    },
    ccMonths() {
      return [...Array(12).keys()].map((i) => i + 1)
    },
    ccYears() {
      const currentYear = new Date().getFullYear()

      return [...Array(10).keys()].map((i) => i + currentYear) 
    },
    cardType () {
      const number = this.cardNumber
      let re = new RegExp('^4')

      if (number.match(re) !== null) return 'visa'

      re = new RegExp('^(34|37)')
      if (number.match(re) !== null) return 'amex'

      re = new RegExp('^5[1-5]')
      if (number.match(re) !== null) return 'mastercard'

      re = new RegExp('^6011')
      if (number.match(re) !== null) return 'discover'
      
      re = new RegExp('^9792')
      if (number.match(re) !== null) return 'troy'

      return null // default type
    },
    isCardExpValid() {
      const ccMonth = parseInt(`${this.form.month}`)
      const ccYear = parseInt(`${this.form.year}`)

      const currMonth = parseInt(`${new Date().getMonth() + 1}`)
      const currYear = parseInt(`${new Date().getFullYear()}`)

      const ccDate = new Date(ccYear, ccMonth)
      const currDate = new Date(currYear, currMonth)

      return ccDate >= currDate
    },
    programsExist() {
      const l = this.$router.resolve({ name: 'Programs' })//check if page exists
      
      return l.resolved.matched.length
    }
  },
  mounted() {
    this.rules.month = [(v) => !!v || 'Month Required', () => this.isCardExpValid || 'Date Expired']
    this.rules.year = [(v) => !!v || 'Year Required', () => this.isCardExpValid || 'Date Expired']
  },
  methods: {
    ...mapActions({
      processPayment: 'orderForm/processPayment'
    }),
    async process() {

      await this.validateData()
      if (this.valid) {
        await this.processPayment({
          ...this.form,
          ...{
            payment_method: this.pay_method,
            exp_date: `${this.form.month < 10 ? '0' : ''}${this.form.month}/${this.form.year}`
          }
        })
      }

      if (!_.isEmpty(this.policy)) {
        this.$emit('process-complete', null)
      }
    },
    async validateData() {
      Object.keys(this.emptyRules).forEach((key) => {
        this.emptyRules[key] = this.rules[key]
      })

      await this.$refs.form.validate()
    }
  }
  
}
</script>
