<template>
  <v-container>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          class="pa-4"
        >
          <h3 class="primary--text py-2">Select Coverage and Location</h3>
          <p>
            Please select the desired Coverage and provide some basic details about the Coverage holders residence and destination, so we can locate the possible Coverage options.
          </p>

        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="4"
          class="pa-4"
        >
          <div class="subtitle-1 font-weight-bold primary--text">
            Primary Residence
          </div>
          <v-autocomplete
            ref="country_from"
            v-model="form.country_from"
            :items="countries"
            :disabled="countries.length < 1"
            item-text="Country"
            item-value="CountryId"
            label="Country"
            :rules="emptyRules.country_from"
          ></v-autocomplete> 
          <v-autocomplete
            ref="state_from"
            v-model="form.state_from"
            item-text="state"
            item-value="stateid"
            :items="statesFrom"
            :disabled="statesFrom.length < 1"
            label="Province/Region"
            :rules="emptyRules.state_from"
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="pa-4"
        >
          <div class="subtitle-1 font-weight-bold primary--text">
            Primary Destination
          </div>
          <v-autocomplete
            ref="country_to"
            v-model="form.country_to"
            :items="countries"
            :disabled="countries.length < 1"
            item-text="Country"
            item-value="CountryId"
            label="Country"
            :rules="emptyRules.country_to"
          ></v-autocomplete> 
          <v-autocomplete
            ref="state_to"
            v-model="form.state_to"
            item-text="state"
            item-value="stateid"
            :items="statesTo"
            :disabled="statesTo.length < 1"
            label="Province/Region"
            :rules="emptyRules.state_to"
          ></v-autocomplete> 

        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="pa-4"
        >
          <div class="subtitle-1 font-weight-bold primary--text">
            Select Type of Coverage
          </div>
          <v-select
            ref="coverage_type"
            v-model="form.coverage_type"
            :disabled="isLoading.products"
            :items="coverageTypes"
            item-text="display"
            item-value="value"
            label="Coverage Types"
            :rules="emptyRules.coverage_type"
            @change="coverageChange"
          ></v-select> 

        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FormRefMixin from '@/open-order/mixins/FormRefMixin'
import LocationsMixin from '@/open-order/mixins/LocationsMixin'
export default {
  name: 'PrimaryCoverageHolder',
  mixins: [FormRefMixin, LocationsMixin],
  data: () => ({
    valid: true,
    rules: {
      country_from: [(v) => !!v || 'Country required'],
      state_from: [],
      country_to: [(v) => !!v || 'Country required'],
      state_to: [],
      coverage_type: [(v) => !!v || 'Coverage Type required']
    }
      
  }),
  computed: {
    ...mapState({
      isLoading: (state) => state.microsite.isLoading,
      products: (state) => state.microsite.products 
    }),
    coverageTypes() {
      const allCoverageTypes = [
        { display: 'CFAR Travel Protection', value: 'CFAR' },
        { display: 'Rental Damage Protection', value: 'RDP' },
        { display: 'Standard Travel Protection', value: 'TI' },
        { display: 'Corp. Damage Protection', value: 'CDP' },
        { display: 'Property Protection', value: 'PPP' },
        { display: 'Master Cancellation', value: 'MC' }
      ]

      return allCoverageTypes.filter((coverage) =>
        this.products.find((product) => product.IsPublic && product.DisplayProductCode === coverage.value)
      )
    } 
  },
  mounted() {
    this.initializeForm();
    this.rules.state_from = this.stateFromRule
    this.rules.state_to = this.stateToRule
  },
  methods: {
    ...mapActions({
      getCountries: 'fieldData/getCountries',
      getStates: 'fieldData/getStates',
      getProducts: 'microsite/getProducts',
      updateOrder: 'orderForm/updateOrder'
    }),

    async initializeForm() {

      try {

        await Promise.all([this.getCountries(), this.getProducts()]);

        const { query } = this.$route

        if (query && Object.keys(query).length !== 0) {

          if (query.residence_country) {
            this.form.country_from = this.findCountryId(query.residence_country)
          }

          if (query.destination_country) {
            this.form.country_to =  this.findCountryId(query.destination_country)
          }

          if (query.residence_state) {
            this.form.state_from = query.residence_state
          }

          if (query.destination_state) {
            this.form.state_to = query.destination_state
          }
        }

      } catch (error) {
        console.log(error);
      };
    },

    findCountryId(countryName) {
      const queryCountryName = countryName?.replace(/\s/g, '').toLowerCase();
      const country = this.countries.find(country => country.Country.replace(/\s/g, '').toLowerCase() === queryCountryName);
      return country?.CountryId || '';
    },

    async validateData() {
      Object.keys(this.emptyRules).forEach((key) => {
        this.emptyRules[key] = this.rules[key]
      })

      await this.$refs.form.validate()
      if (this.valid) {
        this.updateOrder(this.form)
      }

      return this.valid
    },
    coverageChange(e) {
      this.$emit('coverageChange', e)
    }
  }
}
</script>

<style>

</style>