<template>
  <v-container class="d-flex justify-center">
    <v-card v-if="policy && policy.Product" elevation="0" max-width="750" class="text-center">
      <h1>Coverage Successfully Purchased</h1>
      <v-icon
        color="success"
        size="75"
        class="mx-auto my-2"
      >
        mdi-check-circle-outline
      </v-icon>
      <h2 class="text-decoration-underline mb-2">Details</h2>
      <v-simple-table class="details-table">
        <template v-slot:default>
          <tbody>
            <tr>
              <td>Certificate Number:</td>
              <td>{{ policy.CertificateNumber }}</td>
            </tr>
            <tr>
              <td>Product:</td>
              <td>{{ policy.Product.ProductName }}</td>
            </tr>
            <tr>
              <td>Coverage Cost:<br> Processing Fee:</td>
              <td>{{ policy.Product.Retail }} <br> {{ quote.CreditCardProcessingFee }}</td>
            </tr>
            <tr>
              <td>Total Cost:</td>
              <td>{{ quote.FinalAmount }}</td>
            </tr> 
            <tr>
              <td>Effective Date:</td>
              <td>{{ policy.EffectiveDate | formatDate }}</td>
            </tr>
            <tr>
              <td>Cancel By Date:</td>
              <td>{{ policy.CancelByDate | formatDate }}</td>
            </tr><tr>
              <td>Termination Date:</td>
              <td>{{ policy.TerminationDate | formatDate }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div class="text-left mt-2">
        You can get your documents now by clicking on the following link: <a target="_blank" :href="policy.Product.DocLink"><b>DOWNLOAD DOCUMENTS.</b></a><br>
        Thank you for your recent purchase of Play Travel Protection, powered by RentalGuardian.com &reg; and offered by Sandhills Insurance Group, dba InsureStays.
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { CURRENCY } from '@/open-order/constants/currency.js'

export default {

  computed: {
    ...mapState({
      policy: (state) => state.orderForm.policy,
      quote: (state) => state.orderForm.quote
    }),
    currencyOptions() {
      return CURRENCY[0]
    }
  }
}
</script>
<style scoped>
.details-table {
  max-width: 550px;
  margin: 0 auto;
}

.details-table td:first-child {
  text-align: right;
  padding-right:5px;
  width: 50%;
}

.details-table td:nth-child(2) {
  text-align: left;
  padding-left:5px;
}
</style>