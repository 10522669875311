<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateFormatted"
          :label="label"
          persistent-hint
          prepend-icon="mdi-calendar"
          v-bind="attrs"
          :rules="rules"
          :disabled="disabled"
          readonly
          @blur="date = parseDate(dateFormatted)"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        no-title
        :disabled="disabled"
        @input="menu = false"
      ></v-date-picker>
    </v-menu>
  </div>
  
</template>

<script>
export default {
  name: 'TextCalendar',

  props:{
    label: {
      type: String,
      default: 'Date'
    },
    value: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: (vm) => ({
    date: null,
    dateFormatted: null,
    menu: false
  }),

  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    }
  },

  watch: {
    date () {
      this.dateFormatted = this.formatDate(this.date)
      this.handleInput()
    }
  },

  mounted() {
    if (this.value !== null) {
      this.dateFormatted = this.value
    }
  },

  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    handleInput () {
      this.$emit('input', this.dateFormatted)
    }
  }

}

</script>
<style scoped>

</style>
