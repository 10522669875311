<template>
  <v-container>
    <h1>Summary</h1>
    <v-card class="ma-5">
      <v-card-title>Coverage and Location</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr
                v-for="(value, key) in coverage"
                :key="key"
              >
                <td>{{ key }}</td>
                <td>{{ value || '-' }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card class="ma-5">
      <v-card-title>Primary Coverage Holder</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr
                v-for="(value, key) in primary"
                :key="key"
              >
                <td>{{ key }}</td>
                <td>{{ value || '-' }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card class="ma-5">
      <v-card-title>Coverage Details</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr
                v-for="(value, key) in details"
                :key="key"
              >
                <td>{{ key }}</td>
                <td>{{ value || '-' }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-row class="mt-5">
      <v-col class="text-center"> 
        <div class="text-h5"><span class="font-weight-bold">Coverage Plan:</span> {{ quote.ProductName }}</div>
        <div class="text-h6"><span style="margin-right: 25px">Coverage Cost:</span> {{ quote.Retail }}</div>
        <div class="text-h6"><span style="margin-right: 37px">Processing Fee:</span> {{ quote.CreditCardProcessingFee }}</div>
        <div class="text-h5" style="border-top: 2px solid; margin: 0 auto; width: 230px"><span class="font-weight-bold" style="margin-right: 2.4rem">Total Cost:</span>{{ quote.FinalAmount }}</div>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col class="text-center">
        <CreditCardForm @process-complete="nextStep"/>
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import get from 'lodash/get'
import { CURRENCY } from '@/open-order/constants/currency.js'
import CreditCardForm from '@/open-order/components/CreditCardForm'

const _ = { get }

export default {
  components: {
    CreditCardForm
  },
  computed: {
    ...mapState({
      orderForm: (state) => state.orderForm.order,
      quote: (state) => state.orderForm.quote
    }),
    ...mapGetters({
      formatLocations: 'fieldData/formatLocations'
    }),
    currencyOptions() {
      return CURRENCY[0]
    },
    coverage() {
      return {
        'Primary Residence Country': _.get(this.formatLocations.countries, `${this.orderForm.country_from}.Country`, null),
        'Primary Residence Province/Region': _.get(this.formatLocations.stateFrom, `${this.orderForm.state_from}.state`, null),
        'Primary Destination Country': _.get(this.formatLocations.countries, `${this.orderForm.country_to}.Country`, null),
        'Primary Destination Province/Region': _.get(this.formatLocations.stateTo, `${this.orderForm.state_to}.state`, null),
        'Coverage Type': this.orderForm.coverage_type
      }
    },
    details() {
      return {
        'Destination Name': this.orderForm.destination_name,
        'Country': this.coverage['Primary Destination Country'],
        'Province/Region': this.coverage['Primary Destination Province/Region'],
        'City': this.orderForm.city,
        'Zip': this.orderForm.zip,
        'Address': this.orderForm.address,
        'Apt': this.orderForm.apt,
        'Coverage Amount': this.orderForm.coverage_amount,
        'Booking Number': this.orderForm.booking_number,
        'Booking Date': this.orderForm.booking_date,
        'Start Date': this.orderForm.start_date,
        'End Date': this.orderForm.end_date
      }
    },
    primary() {
      return {
        'First Name': this.orderForm.first_name,
        'Last Name': this.orderForm.last_name,
        'Country': this.coverage['Primary Residence Country'],
        'Province/Region': this.coverage['Primary Residence Province/Region'],
        'City': this.orderForm.coverage_city,
        'Zip': this.orderForm.coverage_zip,
        'Address': this.orderForm.coverage_address,
        'Apt': this.orderForm.coverage_apt,
        'Phone Number': this.orderForm.phone_number,
        'Email Address': this.orderForm.email_address
      }
    }
  },
  methods: {
    ...mapActions({
      getStates: 'fieldData/getStates'
    }),
    nextStep() {
      this.$emit('next-step')
    }
  }
}
</script>
<style scoped>
.v-data-table__wrapper td:first-child {
  width: 25%;
}

</style>